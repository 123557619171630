<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: null,

      telemedicina: null,
    };
  },
  methods: {
    getTelemedicina() {
      this.telemedicina = null;

      api
        .get('telemedicina')
        .then((response) => {
          if (response.data.status == 'success') {
            this.telemedicina = response.data.telemedicina;

            if (this.telemedicina.status == 'active') {
              if (this.telemedicina.url) {
                this.statData = [
                  {
                    title: "Status",
                    value: response.data.telemedicina.status,
                  },
                  {
                    title: "Ligue para",
                    value: response.data.telemedicina.phone,
                  },
                  {
                    title: "Prontuário Online",
                    value: response.data.telemedicina.url,
                  },
                ]
              } else {
                this.statData = [
                  {
                    title: "Status",
                    value: response.data.telemedicina.status,
                  },
                  {
                    title: "Ligue para",
                    value: response.data.telemedicina.phone,
                  },
                ]
              }
            } else {
              this.statData = [
                {
                  title: "Status",
                  value: response.data.telemedicina.status,
                },
              ]
            }
          }
        })
    },
  },
  mounted() {
    this.getTelemedicina()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Telemedicina') }}</h3>
        <p></p>
      </div>
    </div>

    <div class="d-lg-flex" style="gap: 20px">
      <div v-for="stat of statData" :key="stat.icon" class="flex-fill">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card d-none">
      <div class="card-body">
      </div>
    </div>
  </Layout>
</template>